import axios from "axios";
import { fetchCsrfToken } from "./csrf";

const axiosInstance = axios.create();

const refreshToken = async (refreshToken: string) => {
  try {
    const response = await axios.post("/api/token", { refreshToken });
    const { token } = response.data;
    localStorage.setItem("authToken", token);
    return token;
  } catch (error) {
    console.error("Failed to refresh token", error);
    throw error;
  }
};

// Handle redirection
const handleRedirect = () => {
  window.location.href = "/login"; // Use window.location.href for redirection
};

// Request interceptor for CSRF token
// axiosInstance.interceptors.request.use(
//   async (config) => {
//     try {
//       const csrfToken = await fetchCsrfToken();
//       if (csrfToken) {
//         config.headers["X-CSRF-Token"] = csrfToken;
//       }
//     } catch (error) {
//       console.error("Error setting CSRF token", error);
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// Cache for CSRF token
const csrfTokenCache: { token: string | null; fetchedAt: number | null } = {
  token: null,
  fetchedAt: null,
};

axiosInstance.interceptors.request.use(
  async (config) => {
    // try {
    //   const now = Date.now();
    //   if (
    //     !csrfTokenCache.token ||
    //     !csrfTokenCache.fetchedAt ||
    //     now - csrfTokenCache.fetchedAt > 60000
    //   ) {
    //     // 1-minute cache
    //     try {
    //       csrfTokenCache.token = await fetchCsrfToken();
    //       csrfTokenCache.fetchedAt = now;
    //     } catch (fetchError) {
    //       console.error("Error fetching CSRF token", fetchError);
    //     }
    //   }
    //   if (csrfTokenCache.token) {
    //     config.headers["X-CSRF-Token"] = csrfTokenCache.token;
    //   }
    // } catch (error) {
    //   console.error("Error setting CSRF token", error);
    // }

    const token = localStorage.getItem("authToken");

    config.headers["Authorization"] = "Bearer " + token;

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error;

    if (response && response.status === 401) {
      const originalRequest = response.config;
      const refreshTokenString = localStorage.getItem("refreshToken");

      if (!refreshTokenString) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        handleRedirect();
        return Promise.reject(error);
      }

      try {
        const { token: newToken } = await refreshToken(refreshTokenString);

        // Update axios instance with new token
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${newToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

        // Retry the original request
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Error refreshing token", refreshError);
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        handleRedirect();
      }
    }

    return Promise.reject(error);
  }
);

export const GET = axiosInstance.get;
export const POST = axiosInstance.get;

export default axiosInstance;
