import axios from "axios";
import {
  BalanceResponse,
  Country,
  Project,
  AvailableNumber,
  NumberResponse,
  SmsResponse,
  NumberData,
} from "../types";
import { GET } from "../utils/axiosInstance";

const API_BASE_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:3002/api";

export const getNumbers = async (
  limit: number,
  offset: number,
  searchTerm: string = "",
  statusFilter: string = "",
  sortOrder: string = "desc"
): Promise<{ numbers: NumberData[]; total: number }> => {
  const response = await GET(`${API_BASE_URL}/numbers`, {
    params: {
      limit,
      offset,
      searchTerm,
      statusFilter,
      sortOrder,
    },
  });

  return response.data;
};

export const getBalance = async (): Promise<BalanceResponse> => {
  const response = await GET<BalanceResponse>(`${API_BASE_URL}/balance`);
  return response.data;
};

export const getCountries = async (): Promise<Country[]> => {
  const response = await GET<Country[]>(`${API_BASE_URL}/countries`);
  return response.data;
};

export const getProjects = async (): Promise<Project[]> => {
  const response = await GET<Project[]>(`${API_BASE_URL}/projects`);
  return response.data;
};

export const getAvailableNumbers = async (
  countryId: number
): Promise<AvailableNumber[]> => {
  const response = await GET<AvailableNumber[]>(
    `${API_BASE_URL}/available-numbers?countryId=${countryId}`
  );
  return response.data;
};

export const requestNumber = async (
  countryId: number,
  projectId: number
): Promise<NumberResponse> => {
  const response = await GET<NumberResponse>(
    `${API_BASE_URL}/request-number?countryId=${countryId}&projectId=${projectId}`
  );
  return response.data;
};

export const getSms = async (requestId: string): Promise<SmsResponse> => {
  const response = await GET<SmsResponse>(
    `${API_BASE_URL}/sms?requestId=${requestId}`
  );
  return response.data;
};
